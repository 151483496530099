
















import { ModuleManagerState } from '@/includes/logic/Modules/ModulesManager'
import { errorNotification } from '@/includes/NotificationService'
import { ModulesTypes } from "@/includes/logic/Modules/ModulesTypes";
import BaseModule from "@/includes/logic/Modules/models/BaseModule";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types/types";
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'
import CurrentModule from '@/components/Modules/components/CurrentModule.vue'
import ModulesRoutesHelperMixin from "@/includes/logic/Modules/mixins/ModulesRoutesHelperMixin";
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'

import { Component, Emit, Mixins, Watch } from 'vue-property-decorator'
import { Guid } from "guid-typescript";

@Component({
  data() {
    return {
      ModuleManagerState,
      SitePermissionEnum
    }
  },
  components: {
    PermissionsBanner,
    CurrentModule,
    PageTitle,
    CenteredColumnLayout
  },
})
export default class CurrentModulePage extends Mixins(TempCurrentModuleMixin, ModulesRoutesHelperMixin) {

  @Emit()
  onBackClick() {
    return null
  }

  get currentModule() {
    return ModuleManagerState?.currentModule
  }

  get settingDisabled() {
    return this.isChatManagerVm && !this.$store.getters.canChangeConfig
  }

  get currentModuleView() {
    if (ModuleManagerState && ModuleManagerState.currentModule) {
      return ModuleManagerState.currentModule.view
    }
  }

  // saveModule() {
  //   const currentModuleExistsInChat = ModuleManagerState!.currentModuleExistsInChat;
  //
  //   return ModuleManagerState!.currentModule!.saveModule()
  //     .then((res) => {
  //       this.$store.dispatch('updateChatState', res.data)
  //         .then(() => {
  //           if (!currentModuleExistsInChat) {
  //             successNotification(this.$t('module_notification_module_add').toString())
  //           } else {
  //             successNotification(this.$t('module_notification_module_save').toString())
  //           }
  //
  //           // EXACT
  //           // ModuleManagerState!.clearCurrentModule()
  //         })
  //
  //     })
  // }

  handleSaveModule() {
    this.$baseTemplate.loader.open()

    this.saveModule()
      .then(() => {
        this.$baseTemplate.loader.close()
      })
    // .then(() => {
    //   this.$router.push({ name: 'ActiveModules' })
    // })
  }

  // beforeRouteLeave(from: Route, to: Route, next: NavigationGuardNext): void {
  //   if (ModuleManagerState) {
  //     if (ModuleManagerState.isCurrentModuleChanged) {
  //       this.$confirm({
  //         title: this.$t('module_confirm_save_before_leave_title').toString(),
  //         content: this.$t('module_confirm_save_before_leave_content').toString(),
  //         okText: this.$t('save_button').toString(),
  //         onOk: () => {
  //           this.saveModule()
  //             .finally(() => {
  //               next()
  //             })
  //         },
  //         onCancel: () => {
  //           ModuleManagerState!.clearCurrentModule()
  //
  //           next()
  //         }
  //       })
  //     } else {
  //       ModuleManagerState!.clearCurrentModule()

  //       next()
  //     }
  //   } else {
  //     next()
  //   }
  // }

  gotoCatalog() {
    this.$router.push({
      name: 'ModulesCatalog'
    })
  }

  moduleNotFound() {
    errorNotification(this.$t('module_notification_module_not_found').toString())

    this.gotoCatalog()
  }

  @Watch('$route.params.CHAT_ID')
  onChatChange() {
    this.initModule()
  }

  initModule() {
    const moduleGuidOrType = this.$route.params.moduleGuidOrType

    if (moduleGuidOrType) {
      if (ModuleManagerState && !ModuleManagerState.currentModule) {
        let module: BaseModule | undefined;

        if (Guid.isGuid(moduleGuidOrType)) {
          module = ModuleManagerState.getModuleByCriteria('guid', moduleGuidOrType.toString())

          if (module) {
            ModuleManagerState.prepareModuleForEdit(module)
          } else {
            this.moduleNotFound()
          }
        } else if (ModulesTypes.some(t => t.name === moduleGuidOrType)) {
          this.setCurrentModule(moduleGuidOrType as ModuleTypesEnum)
        } else {
          this.moduleNotFound()
        }
      }
    } else {
      this.gotoCatalog()
    }
  }

  created() {
    this.initModule()
  }

  mounted() {
    if (!this.$store.getters.canStatistics) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show(this.handleSaveModule)
    }
  }

  destroyed() {
    this.$baseTemplate.saveButton.hide()
    this.$baseTemplate.saveButton.resetSaveButtonCallback()
  }
}
